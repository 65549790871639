<template>
  <v-card class="d-flex pa-2" outlined tile>
    <div class="TerminalSet">
      <div class="TerminalSet">
        <v-container>
        <v-toolbar flat color="grey lighten-2" height="30">
        <v-toolbar-title>端末設定(TerminalSet)</v-toolbar-title>
        </v-toolbar>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field v-model="KgyCd" label="企業コード" maxlength="10"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="KgyNM" label="企業名称" required maxlength="20"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn color="primary" v-on:click="createTerminalSet()"><v-icon left>mdi-pencil</v-icon>保存・更新</v-btn>
            </v-col>
          </v-row>
          <v-row> 
            <v-col cols="12" md="3">
              <v-text-field v-model="BLCKCD" :counter="10" label="部署コード" required></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field v-model="BLCKNM" :counter="10" label="部署名称" required></v-text-field>
            </v-col>
          </v-row> 
          <v-row> 
            <v-col cols="12" md="3">
              <v-text-field v-model="SynCd" :counter="10" label="担当者コード" required></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field v-model="SynNm" :counter="10" label="担当者名称" required></v-text-field>
            </v-col>
          </v-row> 
          <v-row> 
            <v-col cols="12" md="3">
              <v-text-field v-model="TANID" :counter="2" label="端末ID" required></v-text-field>
            </v-col>
          </v-row> 
          <v-row> 
            <v-col cols="12" md="3">
              <v-text-field v-model="GNZHJ" :counter="2" label="現標準税(%)" required></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field v-model="GNZKG" :counter="2" label="現軽減税(%)" required></v-text-field>
            </v-col>
          </v-row> 
          <v-row> 
            <v-col cols="12" md="3">
               <v-menu    :return-value.sync="date" persistent  width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="KGZDT" label="切替日" prepend-icon="mdi-calendar-blank" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="KGZDT" scrollable locale="jp-ja"  :day-format="date => new Date(date).getDate()">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field v-model="KGZHJ" :counter="2" label="次期標準税(%)" required></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field v-model="KGZKG" :counter="2" label="次期軽減税(%)" required></v-text-field>
            </v-col>
          </v-row> 
        </v-container>
      </div>
     </div>
  </v-card>
</template>
<script>
//import Vue from "vue";
export default {
  name: "TerminalSet",
  data() {
    return {
      KgyCd:"",     //初期設定企業コード
      KgyNM:"",     //初期設定企業名称
      BLCKCD:"",    //初期設定部署コード
      BLCKNM:"",    //初期設定部署名称
      SynCd:"",     //初期設定社員名称
      SynNm:"",     //初期設定社員名称
      TANID:"",     //初期設定端末ID
      DENNO:"0",    //初期設定伝票番号
      GNZHJ:"",     //初期設定現在標準税
      GNZKG:"",     //初期設定現在軽減税
      KGZDT:"",     //初期設定消費税切替日
      KGZHJ:"",     //初期設定次期標準税
      KGZKG:"",     //初期設定次期軽減税
      InputData:"",
      date: new Date().toISOString().substr(0, 10),
    }
  },
mounted() {
    if(localStorage.SOGI_KgyCd) this.KgyCd  = localStorage.SOGI_KgyCd;   //初期設定企業コード
    if(localStorage.SOGI_KgyNM) this.KgyNM  = localStorage.SOGI_KgyNM;   //初期設定企業名称
    if(localStorage.SOGI_BLCKCD) this.BLCKCD= localStorage.SOGI_BLCKCD;  //初期設定部署コード
    if(localStorage.SOGI_BLCKNM) this.BLCKNM= localStorage.SOGI_BLCKNM;  //初期設定部署名称
    if(localStorage.SOGI_SynCd) this.SynCd  = localStorage.SOGI_SynCd;   //初期設定社員名称
    if(localStorage.SOGI_SynNm) this.SynNm  = localStorage.SOGI_SynNm;   //初期設定社員名称
    if(localStorage.SOGI_TANID) this.TANID  = localStorage.SOGI_TANID;   //初期設定端末ID
    if(localStorage.SOGI_DENNO) this.DENNO  = localStorage.SOGI_SGDENNO; //初期設定伝票番号
    if(localStorage.SOGI_GNZHJ) this.GNZHJ  = localStorage.SOGI_GNZHJ;   //初期設定現在標準税
    if(localStorage.SOGI_GNZKG) this.GNZKG  = localStorage.SOGI_GNZKG;   //初期設定現在軽減税
    if(localStorage.SOGI_KGZDT) this.KGZDT  = localStorage.SOGI_KGZDT;   //初期設定消費税切替日
    if(localStorage.SOGI_KGZHJ) this.KGZHJ  = localStorage.SOGI_KGZHJ;   //初期設定次期標準税
    if(localStorage.SOGI_KGZKG) this.KGZKG  = localStorage.SOGI_KGZKG;   //初期設定次期軽減税
  },
  methods: {
    createTerminalSet() {
      localStorage.SOGI_KgyCd   = this.KgyCd;
      localStorage.SOGI_KgyNM   = this.KgyNM;
      localStorage.SOGI_BLCKCD  = this.BLCKCD;
      localStorage.SOGI_BLCKNM  = this.BLCKNM;
      localStorage.SOGI_SynCd   = this.SynCd;
      localStorage.SOGI_SynNm   = this.SynNm;
      localStorage.SOGI_TANID   = this.TANID;
      localStorage.SOGI_SGDENNO = this.SGDENNO;
      localStorage.SOGI_GNZHJ   = this.GNZHJ;
      localStorage.SOGI_GNZKG   = this.GNZKG;
      localStorage.SOGI_KGZDT   = this.KGZDT;
      localStorage.SOGI_KGZHJ   = this.KGZHJ;
      localStorage.SOGI_KGZKG   = this.KGZKG;
    }
  }
}
</script>